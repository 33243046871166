import React, { useState, useContext, createContext, ReactNode } from 'react';

type LoadingContextType = {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
};

const defaultLoadingContext: LoadingContextType = {
  loading: false,
  startLoading: () => { },
  stopLoading: () => { },
};

const LoadingContext = createContext<LoadingContextType>(defaultLoadingContext);

export const useLoading = () => useContext(LoadingContext);

type LoadingProviderProps = {
  children: ReactNode;
};

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="text-red-100 text-lg">トランザクションの承認待ち、またはメタデータリフレッシュ中です</div>
          <div className="text-red-100">※閉じないでください</div>
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};
